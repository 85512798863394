<template>
  <div>
    <div class="condition">
      <div class="condition_item">
        <span class="public" style="display: inline-block">
          组织
          <select-tree
            v-if="loading.tree"
            style="width: 150px; margin-right: 10px"
            ref="selectTree"
            :props="props"
            placeholder="请选择组织"
            :options="options"
            :value="deptId"
            :clearable="false"
            :accordion="true"
            :selectTreeDisabled="false"
            @getValue="getValue($event)"
          />
        </span>
        <span class="public" style="display: inline-block">
          客户
          <el-select
            filterable
            style="width: 150px;"
            v-model="customerId"
            placeholder="请选择客户"
          >
            <el-option
              v-for="item in customer"
              :key="item.id"
              :label="item.name"
              :value="item.id + ''"
            >
            </el-option>
          </el-select>
        </span>
        <span class="public" style="display: inline-block">
          设备型号名称
          <el-input
            v-model.trim="deviceFeatureName"
            style="width: 180px;margin-right: 10px"
            clearable
            placeholder="请输入设备型号名称"
          ></el-input>
        </span>
        <span class="public" style="display: inline-block">
          关键字
          <el-input
            v-model.trim="keywordsStr"
            style="width: 160px;margin-right: 10px"
            clearable
            placeholder="请输入设备编码等"
            @input="judgeKeywordsStr"
            @blur="judgeKeywordsStr"
            @clear="clearKeywordsStr"
          ></el-input>
        </span>
      </div>
      <div class="condition_item1">
        <span class="public" style="display: inline-block"
          >统计周期
          <el-select
            v-model="statisticCycle"
            filterable
            placeholder="请选择统计周期"
            style="margin-right: 10px"
            @change="changeStatisticCycle"
            @blur="changeStatisticCycle"
          >
            <el-option
              v-show="statisticCycleDisabled"
              label="天"
              value="day"
            ></el-option>
            <el-option
              v-show="statisticCycleDisabled"
              label="周"
              value="week"
            ></el-option>
            <el-option label="月" value="month"></el-option>
          </el-select>
        </span>
        <span class="public" style="display: inline-block"
          >统计时间
          <el-date-picker
            prefix-icon="el-icon-date"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="statisticTimeStart"
            type="datetime"
            placeholder="选择开始日期时间"
            :picker-options="startPickerOptions"
            :clearable="false"
            @change="judgeStatisticTimeEnd"
            @blur="judgeStatisticTimeEnd"
          >
          </el-date-picker>
          <span style="padding:4px">-</span>
          <el-date-picker
            class="item"
            prefix-icon="el-icon-date"
            v-model="statisticTimeEnd"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择结束日期时间"
            :picker-options="endPickerOptions"
            :clearable="false"
            @change="judgeStatisticTimeEnd"
            @blur="judgeStatisticTimeEnd"
          >
          </el-date-picker>
        </span>
      </div>
      <div class="condition_item1">
        <span class="public" style="display: inline-block"
          >客流量（次）
          <el-input
            v-model.trim.number="visitorFlowTotalStart"
            clearable
            placeholder="请输入客流量"
            style="width: 150px;"
            onkeyup="value=value.replace(/[^\d]/g, '')"
          ></el-input>
          <span>-</span>
          <el-input
            v-model.trim.number="visitorFlowTotalEnd"
            clearable
            placeholder="请输入客流量"
            style="width: 150px;margin-right: 10px"
            onkeyup="value=value.replace(/[^\d]/g, '')"
            @blur="judgeVisitorFlowTotalEnd"
          ></el-input>
        </span>
        <span class="public" style="display: inline-block">
          位置偏移量（米）
          <el-input
            v-model.trim.number="deviationQuantityStart"
            clearable
            placeholder="请输入位置偏移量"
            style="width: 150px;"
            onkeyup="value=value.replace(/[^\d]/g, '')"
          ></el-input>
          <span>-</span>
          <el-input
            v-model.trim.number="deviationQuantityEnd"
            placeholder="请输入位置偏移量"
            style="width: 150px;margin-right: 10px"
            onkeyup="value=value.replace(/[^\d]/g, '')"
            @blur="judgeDeviationQuantity"
          ></el-input>
        </span>
        <span class="public" style="display: inline-block"
          >通电时长（小时）
          <el-input
            v-model.trim.number="electrifyDurationStart"
            clearable
            placeholder="请输入通电时长"
            style="width: 150px;"
            onkeyup="value=value.replace(/[^\d]/g, '')"
          ></el-input>
          <span>-</span>
          <el-input
            v-model.trim.number="electrifyDurationEnd"
            placeholder="请输入通电时长"
            style="width: 150px;margin-right: 10px"
            onkeyup="value=value.replace(/[^\d]/g, '')"
            @blur="judgeElectrifyDurationEnd"
          ></el-input>
        </span>
        <span class="public" style="display: inline-block"
          >开关门次数（次）
          <el-input
            v-model.trim.number="openDoorNumTotalStart"
            clearable
            placeholder="请输入开关门次数"
            style="width: 150px;"
            onkeyup="value=value.replace(/[^\d]/g, '')"
          ></el-input>
          <span>-</span>
          <el-input
            v-model.trim.number="openDoorNumTotalEnd"
            placeholder="请输入开关门次数"
            style="width: 150px;margin-right: 10px"
            onkeyup="value=value.replace(/[^\d]/g, '')"
            @blur="judgeOpenDoorNumTotalEnd"
          ></el-input>
        </span>
        <span class="public" style="display: inline-block"
          >温度（摄氏度）
          <el-input
            v-model.trim.number="reeferTempStart"
            clearable
            placeholder="请输入温度"
            style="width: 150px;"
            onkeyup="value=value.replace(/[^\d]/g, '')"
          ></el-input>
          <span>-</span>
          <el-input
            v-model.trim.number="reeferTempEnd"
            clearable
            placeholder="请输入温度"
            style="width: 150px;margin-right: 10px"
            onkeyup="value=value.replace(/[^\d]/g, '')"
            @blur="judgeReeferTempEnd"
          ></el-input>
        </span>
        <span class="public" style="display: inline-block"
          >营业时间（时）
          <el-input
            v-model.trim.number="businessDurationStart"
            clearable
            placeholder="请输入营业起始时间"
            style="width: 150px;"
            onkeyup="value=value.replace(/[^\d]/g, '')"
          ></el-input>
          <span>-</span>
          <el-input
            v-model.trim.number="businessDurationEnd"
            clearable
            placeholder="请输入营业终止时间"
            style="width: 150px;margin-right: 10px"
            onkeyup="value=value.replace(/[^\d]/g, '')"
            @blur="judgeBusinessDurationEnd"
          ></el-input>
        </span>
        <el-button class="query button_query" @click="queryEquipment(false)"
          >查询</el-button
        >
        <el-button
          class="buttons import"
          icon="el-icon-download"
          @click="exportEquipment"
          >导出</el-button
        >
      </div>
    </div>
    <div class="table">
      <el-table
        stripe
        :header-cell-style="{ background: '#E8EAEF', color: '#3D3D3D' }"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :header-row-class-name="tableRowClassName"
      >
        <el-table-column
          prop="deviceImei"
          label="设备IMEI号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="assetCode"
          label="资产编码"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="customerName"
          label="客户名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="deptName"
          label="组织名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="dateCode"
          label="查询时间"
          align="center"
        ></el-table-column>
        <el-table-column prop="statisticCycle" label="查询周期" align="center">
          <template slot-scope="scope">
            {{
              scope.row.statisticCycle === "day"
                ? "天"
                : scope.row.statisticCycle === "week"
                ? "周"
                : scope.row.statisticCycle === "month"
                ? "月"
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="deviationQuantity"
          label="设备偏移量（米）"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="electrifyDuration"
          label="通电时长（小时）"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="visitorFlowTotal"
          label="客流量（次）"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="openDoorNumTotal"
          label="开关门次数（次）"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="reeferTemp"
          label="冷藏柜温度（摄氏度）"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="businessElectrifyDuration"
          label="设备营业时间通电总时长（时）"
          align="center"
        ></el-table-column>
        <el-table-column prop="deviceStatus" label="设备状态" align="center">
          <template slot-scope="scope">
            {{
              scope.row.deviceStatus === 1
                ? "正常"
                : scope.row.deviceStatus === 2
                ? "维修"
                : scope.row.deviceStatus === 3
                ? "报废"
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="deviceOnlineStatus"
          label="在线状态"
          align="center"
        >
          <template slot-scope="scope">
            {{
              scope.row.deviceOnlineStatus === 1
                ? "在线"
                : scope.row.deviceOnlineStatus === 2
                ? "不在线"
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="deviceType"
          label="设备类别"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="deviceCode"
          label="设备编码"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="deviceFeatureName"
          label="设备型号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="putOrg"
          label="投放机构"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="putArea"
          label="投放区域"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="putAreaTime"
          label="投放时间"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="salesMan"
          label="业务代表"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="salesManPhone"
          label="业务代表联系方式"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="deposit"
          label="押金"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="shopOwner"
          label="店主"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="shopOwnerPhone"
          label="店主联系方式"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="shopAddress"
          label="门店地址"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="contractStartTime"
          label="合约开始时间"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="contractEndTime"
          label="合约结束时间"
          align="center"
        ></el-table-column>
      </el-table>
      <div v-if="showPage" class="app-page-container">
        <span @click="first">首页</span>
        <el-pagination
          layout="pager"
          :hide-on-single-page="false"
          :current-page.sync="curPage"
          :page-size="12"
          :total="recordCount"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
        <span @click="last">尾页</span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  exportDeviceBigDataStatistics,
  getDeviceBigDataStatistics
} from "@/api/equipmentDataStatistics";
import { clientName, queryDeptAll } from "@/api/AssetManagement";

export default {
  name: "EquipmentDataStatistics",
  data() {
    return {
      loading: {
        tree: false
      },
      props: {
        value: "deptId",
        label: "deptName",
        children: "children"
      },
      options: [],
      // deptId: "",
      deptId: 4,
      customer: [],
      organizationName: "",
      datePickerDisabled: true,
      startPickerOptions: {},
      endPickerOptions: {},
      valueFormat: "yyyy-MM-dd",
      deptName: "", //组织名称
      customerId: undefined, // 客户id
      customerName: "", //客户名称
      deviceFeatureName: "", //设备型号名称
      keyword: "", //设备编码 （设备编码、资产编码、RFID、IMEI）
      keywordsStr: "", //设备编码 （设备编码、资产编码、RFID、IMEI）
      statisticCycle: "month", //统计周期 day-天，周-week，月-month
      statisticCycleDisabled: false,
      statisticTimeStart: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        new Date().getHours(),
        new Date().getMinutes(),
        new Date().getSeconds()
      ), //统计时间起 yyyy-MM-dd HH:mm:sss
      statisticTimeEnd: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        new Date().getHours(),
        new Date().getMinutes(),
        new Date().getSeconds()
      ), //统计时间止 yyyy-MM-dd HH:mm:sss （按天统计时可以选择具体的日期、按周统计时可以选择具体的日期、按月统计时选择起始月和结束月）；可以查询之前5年的数据，起止时间间隔不能超过3个月。
      visitorFlowTotalStart: undefined, // 累计客流量起
      visitorFlowTotalEnd: undefined, // 累计客流量止
      deviationQuantityStart: undefined, // 设备偏移量起
      deviationQuantityEnd: undefined, // 设备偏移量止
      electrifyDurationStart: undefined, // 设备通电时长起
      electrifyDurationEnd: undefined, // 设备通电时长止
      openDoorNumTotalStart: undefined, // 累计开关门次数起
      openDoorNumTotalEnd: undefined, // 累计开关门次数止
      reeferTempStart: undefined, // 设备冷藏柜平均温度起
      reeferTempEnd: undefined, // 设备冷藏柜平均温度止
      businessDurationStart: 0, // 营业时间起 （**点~**点、范围24小时内，开始时间不可晚于结束时间）
      businessDurationEnd: 23, // 营业时间止
      tableData: [],
      showPage: true,
      curPage: 1,
      limit: 12,
      pages: 1, //页数
      recordCount: 0, //总数
      disabledEndPicker: false
    };
  },
  watch: {
    statisticCycle() {
      if (this.statisticCycle === "day") {
        return (this.valueFormat = "yyyy-MM-dd");
      } else if (this.statisticCycle === "week") {
        return (this.valueFormat = "yyyy-MM-dd");
      } else if (this.statisticCycle === "month") {
        return (this.valueFormat = "yyyy-MM-dd");
      }
    }
  },
  created() {
    this.getorganization();
    this.getclientName();
  },
  methods: {
    changeStatisticCycle: function(val) {
      const keywords = this.keywordsStr
        .replace(/\s*/g, "")
        .split(/[\uff0c|,]/)
        .filter(item => item !== "")
        .map(item => {
          return item.replace(/\s*/g, "");
        });
      if (val === "day") {
        // if ((keywords.length === 0) || (keywords.length > 1 && keywords.length <= 10)) {
        //   this.startPickerOptions = {
        //     disabledDate() {
        //       return false;
        //     }
        //   };
        //   this.endPickerOptions = {
        //     disabledDate() {
        //       return false;
        //     }
        //   };
        // } else {
        //   this.startPickerOptions = {
        //     disabledDate() {
        //       return true;
        //     }
        //   };
        //   this.endPickerOptions = {
        //     disabledDate() {
        //       return true;
        //     }
        //   };
        // }
        if (keywords.length === 1) {
          if (val === "day") {
            this.startPickerOptions = {
              disabledDate() {
                return false;
              }
            };
            this.endPickerOptions = {
              disabledDate() {
                return false;
              }
            };
          }
        } else {
          this.startPickerOptions = {
            disabledDate() {
              return false;
            }
          };
          this.endPickerOptions = {
            disabledDate() {
              return false;
            }
          };
        }
      } else {
        // 当单设备统计周期按照周查询时，分页参数无效，页面不分页，时间跨度不超过365天，即最多展示52条周统计数据。
        if (keywords.length === 1) {
          if (val === "week") {
            this.showPage = false;
            this.curPage = undefined;
            this.limit = undefined;
            this.pages = undefined;
            this.recordCount = undefined;
          } else {
            this.showPage = true;
            this.curPage = 1;
            this.limit = 12;
            this.pages = 1;
            this.recordCount = 0;
          }
        }
        this.startPickerOptions = {
          disabledDate() {
            return false;
          }
        };
        this.endPickerOptions = {
          disabledDate() {
            return false;
          }
        };
      }
    },
    clearKeywordsStr() {
      if (this.statisticCycle === "day") {
        this.startPickerOptions = {
          disabledDate() {
            return true;
          }
        };
        this.endPickerOptions = {
          disabledDate() {
            return true;
          }
        };
      }
    },
    judgeKeywordsStr() {
      const keywords = this.keywordsStr
        .replace(/\s*/g, "")
        .split(/[\uff0c|,]/)
        .filter(item => item !== "")
        .map(item => {
          return item.replace(/\s*/g, "");
        });
      // 当关键字输入为空或者输入大于1小于10个编码时，统计周期只能选择月，时间段可以选择起始和终止时间。
      if (
        keywords.length === 0 ||
        (keywords.length > 1 && keywords.length <= 10)
      ) {
        this.statisticCycle = "month";
        this.statisticCycleDisabled = false;
      }
      // 当关键字输入等于1个编码时，统计周期可以选择天、周、月。即只有单设备查询支持按照天、周，月查询。
      if (keywords.length === 1) {
        this.statisticCycleDisabled = true;
      }
      if (keywords.length > 10) {
        this.$message({
          showClose: true,
          message: "输入的号码数量不能超过10个",
          type: "error"
        });
        this.keywordsStr = "";
        this.statisticCycleDisabled = false;
        if (this.statisticCycle === "day") {
          this.startPickerOptions = {
            disabledDate() {
              return true;
            }
          };
          this.endPickerOptions = {
            disabledDate() {
              return true;
            }
          };
        }
      }
      // if (keywords.length === 0) {
      //   this.keywordsStr = "";
      //   if (this.statisticCycle === "day") {
      //     this.startPickerOptions = {
      //       disabledDate() {
      //         return true;
      //       }
      //     };
      //     this.endPickerOptions = {
      //       disabledDate() {
      //         return true;
      //       }
      //     };
      //   }
      // }
      // if (keywords.length > 0 && keywords.length <= 10) {
      //   if (this.statisticCycle === "day") {
      //     this.startPickerOptions = {
      //       disabledDate() {
      //         return false;
      //       }
      //     };
      //     this.endPickerOptions = {
      //       disabledDate() {
      //         return false;
      //       }
      //     };
      //   }
      // }
    },
    judgeStatisticTimeEnd() {
      if (this.statisticTimeStart === "") {
        this.statisticTimeStart = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          new Date().getHours(),
          new Date().getMinutes(),
          new Date().getSeconds()
        );
      }
      if (this.statisticTimeEnd === "") {
        this.statisticTimeEnd = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          new Date().getHours(),
          new Date().getMinutes(),
          new Date().getSeconds()
        );
      }
      if (
        new Date(this.statisticTimeStart).getTime() >
        new Date(this.statisticTimeEnd).getTime()
      ) {
        this.$message({
          showClose: true,
          message: "起始统计时间必须小于终止统计时间",
          type: "error"
        });
        this.statisticTimeEnd = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          new Date().getHours(),
          new Date().getMinutes(),
          new Date().getSeconds()
        );
        this.statisticTimeStart = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          new Date().getHours(),
          new Date().getMinutes(),
          new Date().getSeconds()
        );
      }
      const onePingYear =
        new Date("1997-12-31 23:59:59").getTime() -
        new Date("1997-01-01 00:00:00").getTime();
      if (
        new Date(this.statisticTimeEnd).getTime() -
          new Date(this.statisticTimeStart).getTime() >
        onePingYear
      ) {
        this.$message({
          showClose: true,
          message: "起止统计时间不能超过一年",
          type: "error"
        });
        this.statisticTimeEnd = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          new Date().getHours(),
          new Date().getMinutes(),
          new Date().getSeconds()
        );
        this.statisticTimeStart = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          new Date().getHours(),
          new Date().getMinutes(),
          new Date().getSeconds()
        );
      }
    },
    judgeVisitorFlowTotalEnd() {
      if (this.visitorFlowTotalStart === "") {
        this.visitorFlowTotalStart = undefined;
      }
      if (this.visitorFlowTotalEnd === "") {
        this.visitorFlowTotalEnd = undefined;
      }
      if (this.visitorFlowTotalStart === 0) {
        this.visitorFlowTotalStart = undefined;
      }
      if (this.visitorFlowTotalEnd === 0) {
        this.visitorFlowTotalEnd = undefined;
      }
      if (this.visitorFlowTotalStart >= this.visitorFlowTotalEnd) {
        this.$message({
          showClose: true,
          message: "起始客流量必须小于终止客流量时间",
          type: "error"
        });
      }
      this.visitorFlowTotalStart = undefined;
      this.visitorFlowTotalEnd = undefined;
    },
    judgeDeviationQuantity() {
      if (this.visitorFlowTotalStart === "") {
        this.visitorFlowTotalStart = undefined;
      }
      if (this.visitorFlowTotalEnd === "") {
        this.visitorFlowTotalEnd = undefined;
      }
      if (this.visitorFlowTotalStart === 0) {
        this.visitorFlowTotalStart = undefined;
      }
      if (this.visitorFlowTotalEnd === 0) {
        this.visitorFlowTotalEnd = undefined;
      }
      if (this.deviationQuantityStart >= this.deviationQuantityEnd) {
        this.$message({
          showClose: true,
          message: "起始位置偏移量必须小于终止位置偏移量",
          type: "error"
        });
      }
      this.deviationQuantityStart = undefined;
      this.deviationQuantityEnd = undefined;
    },
    judgeElectrifyDurationEnd() {
      if (this.electrifyDurationStart === "") {
        this.electrifyDurationStart = undefined;
      }
      if (this.electrifyDurationEnd === "") {
        this.electrifyDurationEnd = undefined;
      }
      if (this.electrifyDurationStart === 0) {
        this.electrifyDurationStart = undefined;
      }
      if (this.electrifyDurationEnd === 0) {
        this.electrifyDurationEnd = undefined;
      }
      if (this.electrifyDurationStart >= this.electrifyDurationEnd) {
        this.$message({
          showClose: true,
          message: "起始通电时长必须小于终止通电时长",
          type: "error"
        });
      }
      this.electrifyDurationStart = undefined;
      this.electrifyDurationEnd = undefined;
    },
    judgeOpenDoorNumTotalEnd() {
      if (this.openDoorNumTotalStart === "") {
        this.openDoorNumTotalStart = undefined;
      }
      if (this.openDoorNumTotalEnd === "") {
        this.openDoorNumTotalEnd = undefined;
      }
      if (this.openDoorNumTotalStart === 0) {
        this.openDoorNumTotalStart = undefined;
      }
      if (this.openDoorNumTotalEnd === 0) {
        this.openDoorNumTotalEnd = undefined;
      }
      if (this.openDoorNumTotalStart >= this.openDoorNumTotalEnd) {
        this.$message({
          showClose: true,
          message: "起始开关门次数必须小于终止开关门次数",
          type: "error"
        });
      }
      this.openDoorNumTotalStart = undefined;
      this.openDoorNumTotalEnd = undefined;
    },
    judgeReeferTempEnd() {
      if (this.reeferTempStart === "") {
        this.reeferTempStart = undefined;
      }
      if (this.reeferTempEnd === "") {
        this.reeferTempEnd = undefined;
      }
      if (this.reeferTempStart === 0) {
        this.reeferTempStart = undefined;
      }
      if (this.reeferTempEnd === 0) {
        this.reeferTempEnd = undefined;
      }
      if (this.reeferTempStart >= this.reeferTempEnd) {
        this.$message({
          showClose: true,
          message: "起始温度必须小于终止温度",
          type: "error"
        });
      }
      this.reeferTempStart = undefined;
      this.reeferTempEnd = undefined;
    },
    judgeBusinessDurationEnd() {
      if (this.businessDurationStart === "") {
        this.businessDurationStart = 0;
      }
      if (this.businessDurationEnd === "") {
        this.businessDurationEnd = 23;
      }
      if (this.businessDurationStart >= this.businessDurationEnd) {
        this.$message({
          showClose: true,
          message: "营业起始时间必须小于营业终止时间",
          type: "error"
        });
      }
      this.businessDurationStart = 0;
      this.businessDurationEnd = 23;
    },
    //查询
    getQueryForm() {
      const keyWords = this.keywordsStr
        .replace(/\s*/g, "")
        .split(/[\uff0c|,]/)
        .filter(item => item !== "")
        .map(item => {
          return item.replace(/\s*/g, "");
        });
      return {
        curPage:
          keyWords.length === 1 && this.statisticCycle === "week"
            ? undefined
            : this.pages,
        limit:
          keyWords.length === 1 && this.statisticCycle === "week"
            ? undefined
            : 12,
        userId: localStorage.getItem("userId"),
        deptId: 4, //部门ID
        customerId:
          this.customerId === undefined
            ? this.customer.length !== 0
              ? this.customer[0].id + ""
              : undefined
            : this.customerId, //客户名称
        deviceFeatureName: this.deviceFeatureName, //设备型号名称
        keywordsStr: keyWords.join(","), //设备编码 （设备编码、资产编码、RFID、IMEI）
        statisticCycle: this.statisticCycle, //统计周期 day-天，周-week，月-month
        statisticTimeStart:
          this.statisticTimeStart !== ""
            ? new Date(this.statisticTimeStart).getFullYear() +
              "-" +
              (new Date(this.statisticTimeStart).getMonth() + 1 < 10
                ? "0" + (new Date(this.statisticTimeStart).getMonth() + 1)
                : new Date(this.statisticTimeStart).getMonth() + 1) +
              "-" +
              (new Date(this.statisticTimeStart).getDate() < 10
                ? "0" + new Date(this.statisticTimeStart).getDate()
                : new Date(this.statisticTimeStart).getDate()) +
              " " +
              (new Date(this.statisticTimeStart).getHours() < 10
                ? "0" + new Date(this.statisticTimeStart).getHours()
                : new Date(this.statisticTimeStart).getHours()) +
              ":" +
              (new Date(this.statisticTimeStart).getMinutes() < 10
                ? "0" + new Date(this.statisticTimeStart).getMinutes()
                : new Date(this.statisticTimeStart).getMinutes()) +
              ":" +
              (new Date(this.statisticTimeStart).getSeconds() < 10
                ? "0" + new Date(this.statisticTimeStart).getSeconds()
                : new Date(this.statisticTimeStart).getSeconds())
            : "", //统计时间起 yyyy-MM-dd HH:mm:sss
        statisticTimeEnd:
          this.statisticTimeEnd !== ""
            ? new Date(this.statisticTimeEnd).getFullYear() +
              "-" +
              (new Date(this.statisticTimeEnd).getMonth() + 1 < 10
                ? "0" + (new Date(this.statisticTimeEnd).getMonth() + 1)
                : new Date(this.statisticTimeEnd).getMonth() + 1) +
              "-" +
              (new Date(this.statisticTimeEnd).getDate() < 10
                ? "0" + new Date(this.statisticTimeEnd).getDate()
                : new Date(this.statisticTimeEnd).getDate()) +
              " " +
              (new Date(this.statisticTimeEnd).getHours() < 10
                ? "0" + new Date(this.statisticTimeEnd).getHours()
                : new Date(this.statisticTimeEnd).getHours()) +
              ":" +
              (new Date(this.statisticTimeEnd).getMinutes() < 10
                ? "0" + new Date(this.statisticTimeEnd).getMinutes()
                : new Date(this.statisticTimeEnd).getMinutes()) +
              ":" +
              (new Date(this.statisticTimeEnd).getSeconds() < 10
                ? "0" + new Date(this.statisticTimeEnd).getSeconds()
                : new Date(this.statisticTimeEnd).getSeconds())
            : "", //统计时间止 yyyy-MM-dd HH:mm:sss
        visitorFlowTotalStart:
          this.visitorFlowTotalStart === undefined || 0
            ? null
            : this.visitorFlowTotalStart, // 累计客流量起
        visitorFlowTotalEnd:
          this.visitorFlowTotalEnd === undefined || 0
            ? null
            : this.visitorFlowTotalEnd, // 累计客流量止
        deviationQuantityStart:
          this.deviationQuantityStart === undefined || 0
            ? null
            : this.deviationQuantityStart, // 设备偏移量起
        deviationQuantityEnd:
          this.deviationQuantityEnd === undefined || 0
            ? null
            : this.deviationQuantityEnd, // 设备偏移量止
        electrifyDurationStart:
          this.electrifyDurationStart === undefined || 0
            ? null
            : this.electrifyDurationStart, // 设备通电时长起
        electrifyDurationEnd:
          this.electrifyDurationEnd === undefined || 0
            ? null
            : this.electrifyDurationEnd, // 设备通电时长止
        openDoorNumTotalStart:
          this.openDoorNumTotalStart === undefined || 0
            ? null
            : this.openDoorNumTotalStart, // 累计开关门次数起
        openDoorNumTotalEnd:
          this.openDoorNumTotalEnd === undefined || 0
            ? null
            : this.openDoorNumTotalEnd, // 累计开关门次数止
        reeferTempStart:
          this.reeferTempStart === undefined || 0 ? null : this.reeferTempStart, // 设备冷藏柜平均温度起
        reeferTempEnd:
          this.reeferTempEnd === undefined || 0 ? null : this.reeferTempEnd, // 设备冷藏柜平均温度止
        businessDurationStart:
          this.businessDurationStart === "" ? 0 : this.businessDurationStart, // 营业时间起 （**点~**点、范围24小时内，开始时间不可晚于结束时间）
        businessDurationEnd:
          this.businessDurationEnd === "" ? 23 : this.businessDurationEnd // 营业时间止
      };
    },
    getorganization() {
      const config = {
        params: {
          userId: localStorage.getItem("userId")
        }
      };
      queryDeptAll(config).then(({ data }) => {
        const arr = data.data || [];
        arr.forEach(value => {
          value.children = [];
          arr.forEach(val => {
            if (value.deptId === val.parentId) {
              value.children.push(val);
            }
          });
          if (value.deptId === parseInt(localStorage.getItem("deptId"), 10)) {
            this.options.push(value);
          }
        });
        this.loading.tree = true;
      });
    },
    getclientName() {
      clientName({}).then(res => {
        this.customer = res.data.data;
        if (this.customer.length !== 0) {
          this.customerId = this.customer[0].id + "";
          this.queryEquipment(false);
          this.judgeKeywordsStr();
        }
      });
    },
    // 查询
    queryEquipment(type) {
      if (!type) {
        this.curPage = 1;
        this.pages = 1;
      }
      let data = this.getQueryForm();
      getDeviceBigDataStatistics(data).then(res => {
        this.recordCount = res.data.data.total;
        this.pages = res.data.data.pages;
        this.tableData = res.data.data.records;
      });
    },
    //分页首页
    first() {
      this.tableData = [];
      this.curPage = 1;
      this.pages = 1;
      this.queryEquipment(true);
    },
    //分页最后一页
    last() {
      this.curPage = this.recordCount;
      this.pages = Math.ceil(this.recordCount / 12);
      this.queryEquipment(true);
    },
    handleCurrentChange(val) {
      this.pages = val;
      this.queryEquipment(true);
    },
    getValue(value) {
      this.organizationName = value;
    },
    //导出
    exportEquipment() {
      this.$store.commit("SET_LOADING", true);
      const keyWords = this.keywordsStr
        .replace(/\s*/g, "")
        .split(/[\uff0c|,]/)
        .filter(item => item !== "")
        .map(item => {
          return item.replace(/\s*/g, "");
        });
      let data = {
        curPage:
          keyWords.length === 1 && this.statisticCycle === "week"
            ? undefined
            : this.pages,
        limit:
          keyWords.length === 1 && this.statisticCycle === "week"
            ? undefined
            : 12,
        token: localStorage.getItem("token"),
        userId: localStorage.getItem("userId"),
        // deptId: this.organizationName, //部门ID
        deptId: 4, //部门ID
        customerId:
          this.customerId === undefined
            ? this.customer.length !== 0
              ? this.customer[0].id + ""
              : undefined
            : this.customerId, //客户名称
        deviceFeatureName: this.deviceFeatureName, //设备型号名称
        keywordsStr: keyWords.join(","), //设备编码 （设备编码、资产编码、RFID、IMEI）
        statisticCycle: this.statisticCycle, //统计周期 day-天，周-week，月-month
        statisticTimeStart:
          this.statisticTimeStart !== ""
            ? new Date(this.statisticTimeStart).getFullYear() +
              "-" +
              (new Date(this.statisticTimeStart).getMonth() + 1 < 10
                ? "0" + (new Date(this.statisticTimeStart).getMonth() + 1)
                : new Date(this.statisticTimeStart).getMonth() + 1) +
              "-" +
              (new Date(this.statisticTimeStart).getDate() < 10
                ? "0" + new Date(this.statisticTimeStart).getDate()
                : new Date(this.statisticTimeStart).getDate()) +
              " " +
              (new Date(this.statisticTimeStart).getHours() < 10
                ? "0" + new Date(this.statisticTimeStart).getHours()
                : new Date(this.statisticTimeStart).getHours()) +
              ":" +
              (new Date(this.statisticTimeStart).getMinutes() < 10
                ? "0" + new Date(this.statisticTimeStart).getMinutes()
                : new Date(this.statisticTimeStart).getMinutes()) +
              ":" +
              (new Date(this.statisticTimeStart).getSeconds() < 10
                ? "0" + new Date(this.statisticTimeStart).getSeconds()
                : new Date(this.statisticTimeStart).getSeconds())
            : "", //统计时间起 yyyy-MM-dd HH:mm:sss
        statisticTimeEnd:
          this.statisticTimeEnd !== ""
            ? new Date(this.statisticTimeEnd).getFullYear() +
              "-" +
              (new Date(this.statisticTimeEnd).getMonth() + 1 < 10
                ? "0" + (new Date(this.statisticTimeEnd).getMonth() + 1)
                : new Date(this.statisticTimeEnd).getMonth() + 1) +
              "-" +
              (new Date(this.statisticTimeEnd).getDate() < 10
                ? "0" + new Date(this.statisticTimeEnd).getDate()
                : new Date(this.statisticTimeEnd).getDate()) +
              " " +
              (new Date(this.statisticTimeEnd).getHours() < 10
                ? "0" + new Date(this.statisticTimeEnd).getHours()
                : new Date(this.statisticTimeEnd).getHours()) +
              ":" +
              (new Date(this.statisticTimeEnd).getMinutes() < 10
                ? "0" + new Date(this.statisticTimeEnd).getMinutes()
                : new Date(this.statisticTimeEnd).getMinutes()) +
              ":" +
              (new Date(this.statisticTimeEnd).getSeconds() < 10
                ? "0" + new Date(this.statisticTimeEnd).getSeconds()
                : new Date(this.statisticTimeEnd).getSeconds())
            : "", //统计时间止 yyyy-MM-dd HH:mm:sss
        visitorFlowTotalStart:
          this.visitorFlowTotalStart === undefined || 0
            ? null
            : this.visitorFlowTotalStart, // 累计客流量起
        visitorFlowTotalEnd:
          this.visitorFlowTotalEnd === undefined || 0
            ? null
            : this.visitorFlowTotalEnd, // 累计客流量止
        deviationQuantityStart:
          this.deviationQuantityStart === undefined || 0
            ? null
            : this.deviationQuantityStart, // 设备偏移量起
        deviationQuantityEnd:
          this.deviationQuantityEnd === undefined || 0
            ? null
            : this.deviationQuantityEnd, // 设备偏移量止
        electrifyDurationStart:
          this.electrifyDurationStart === undefined || 0
            ? null
            : this.electrifyDurationStart, // 设备通电时长起
        electrifyDurationEnd:
          this.electrifyDurationEnd === undefined || 0
            ? null
            : this.electrifyDurationEnd, // 设备通电时长止
        openDoorNumTotalStart:
          this.openDoorNumTotalStart === undefined || 0
            ? null
            : this.openDoorNumTotalStart, // 累计开关门次数起
        openDoorNumTotalEnd:
          this.openDoorNumTotalEnd === undefined || 0
            ? null
            : this.openDoorNumTotalEnd, // 累计开关门次数止
        reeferTempStart:
          this.reeferTempStart === undefined || 0 ? null : this.reeferTempStart, // 设备冷藏柜平均温度起
        reeferTempEnd:
          this.reeferTempEnd === undefined || 0 ? null : this.reeferTempEnd, // 设备冷藏柜平均温度止
        businessDurationStart:
          this.businessDurationStart === "" ? 0 : this.businessDurationStart, // 营业时间起 （**点~**点、范围24小时内，开始时间不可晚于结束时间）
        businessDurationEnd:
          this.businessDurationEnd === "" ? 23 : this.businessDurationEnd // 营业时间止
      };
      exportDeviceBigDataStatistics(data)
        .then(res => {
          const dt = new Date();
          const y = dt.getFullYear();
          const m = (dt.getMonth() + 1 + "").padStart(2, "0");
          const d = (dt.getDate() + "").padStart(2, "0");
          const hh = (dt.getHours() + "").padStart(2, "0");
          const mm = (dt.getMinutes() + "").padStart(2, "0");
          const ss = (dt.getSeconds() + "").padStart(2, "0");
          let date = y + m + d + hh + mm + ss;
          const content = res.data; //返回的内容
          let _this = this;
          if (res.data.type !== "application/vnd.ms-excel") {
            //二进制转字符串
            let reader = new FileReader();
            reader.addEventListener("loadend", function(e) {
              // 输出字符串 转化为json对象
              let str = JSON.parse(e.target.result);
              _this.$message({
                showClose: true,
                message: str.desc,
                type: "error"
              });
              if (str.resultCode === 2000) {
                _this.$router.push("/login");
              }
            });
            reader.readAsText(content);
          } else {
            const fileName = "设备数据统计" + date.toString() + ".xls"; //下载文件名
            //处理下载流
            const blob = new Blob([content]); //创建一个类文件对象：Blob对象表示一个不可变的、原始数据的类文件对象
            const url = window.URL.createObjectURL(blob); //URL.createObjectURL(object)表示生成一个File对象或Blob对象
            let dom = document.createElement("a"); //设置一个隐藏的a标签，href为输出流，设置download
            dom.style.display = "none";
            dom.href = url;
            dom.setAttribute("download", fileName); //指示浏览器下载url,而不是导航到它；因此将提示用户将其保存为本地文件
            document.body.appendChild(dom);
            dom.click();
          }
        })
        .finally(() => {
          this.$store.commit("SET_LOADING", false);
        });
    },
    tableRowClassName(row, rowIndex) {
      if (rowIndex === 0) return "headerStyle";
    }
  }
};
</script>

<style scoped>
.condition {
  margin-bottom: 20px;
}
.condition_item {
  margin-left: 10px;
  margin-bottom: 10px;
}
.condition_item1 {
  margin-left: 10px;
}
.button_query {
  margin-left: 10px;
}
.public {
  margin-left: 10px;
  margin-bottom: 20px;
}
/deep/ .el-table__expanded-cell[class*="cell"] {
  padding: 0 0 0 48px;
}
/deep/
  .el-table__body
  .el-table__header-wrapper
  .el-table__header
  .el-table__cell {
  padding: 0;
}

/deep/ .el-table__body-wrapper .expanded .el-table__cell {
  background-color: rgb(246, 246, 246);
}
</style>
