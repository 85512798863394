import axios from "../utils/request";

//获取设备大数据
export function getDeviceBigDataStatistics(params) {
  return axios({
    url: "/clodchainAM/bigData/get_device_big_data_statistics",
    method: "get",
    params
  });
}

//导出
export function exportDeviceBigDataStatistics(data) {
  return axios({
    url: "/clodchainAM/bigData/batch_export_device_statistics",
    method: "get",
    params: data,
    responseType: "blob"
  });
}
